import MainContener from "../../Layout/MainContener";
import "./Events.css" ;



function Events(){
    return(
        <MainContener>
            <div className="CoverEvents">
                <div className="EventsText">
                    <p>Max Events</p>
                </div>


                 <div className="EventsDiv">



                    {Array.from(new Array(4)).map((mt,i)=>{
                        return (
                        <div className="EventDiv" key={i} style={{backgroundColor:`rgb(${Math.floor(Math.random()*255)},${Math.floor(Math.random()*255)},${Math.floor(Math.random()*255)})`}}>
                            <a href="/" onClick={e=>e.preventDefault()}  rel="noreferrer" >
                                <img src="Files/Photo/Coming Soon 3.jpeg" alt="No" className="TTTTT"/>
                                <p>SooN</p>
                            </a>
                        </div>
                        )
                    })}
                </div> 
                

            </div>
        </MainContener>

    );
};

export default Events ;