import MainContener from "../../Layout/MainContener";
import "./About.css" ;

function About(){
    return (
        <MainContener>

            <div className="CoverAbout">
                <div className="Pdiv">
                    <div className="DivA">
                        <div>
                            <div className="ShDivSp DivA1">
                                <div className="PPAboutDiv">
                                    <img className="ImgAbout"  src="Files/Photo/zakimax.png" alt="Not Found" />
                                </div>

                            </div>

                            <div className="ShDivSp DivA2">
                                <div>
                                    <p>Zaki Mohammed - Zaki Max</p>
                                    <p>Founder of Max Company</p>
                                    <p>Iraq - Baghdad</p>
                                    <p>Computer Engineer ( Information Engineer )</p>
                                    <p>Full-Stack Developers</p>
                                    <p>28 th</p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="DivB">
                        <div>
                            <div className="ShDivSp DivB1">
                                <div className="SkillsDiv">
                                    <p>My Skills</p>
                                    <div class="progress">
                                        <div class="progress-value TDP">Tea Drinking   1000%</div>
                                    </div>

                                    <div class="progress">
                                        <div class="progress-value CppP">C++   90%</div>
                                    </div>

                                    <div class="progress">
                                        <div class="progress-value JsP">JavaScript   90%</div>
                                    </div>

                                    <div class="progress">
                                        <div class="progress-value HtmlP">HTML   90%</div>
                                    </div>

                                    <div class="progress">
                                        <div class="progress-value CssP">CSS   80%</div>
                                    </div>

                                    <div class="progress">
                                        <div class="progress-value ReactJsP">React JS   99%</div>
                                    </div>

                                    <div class="progress">
                                        <div class="progress-value ReduxP">Redux   80%</div>
                                    </div>

                                    <div class="progress">
                                        <div class="progress-value ReactNativeP">ReactNative   30%</div>
                                    </div>

                                    <div class="progress">
                                        <div class="progress-value NodeJsP">Node JS   80%</div>
                                    </div>

                                    <div class="progress">
                                        <div class="progress-value MSP">MySql   95%</div>
                                    </div>
                                </div>
                            </div>

                            <div className="ShDivSp DivB2">
                                
                                <div className="SkillsDiv">
                                        <div class="progress">
                                            <div class="progress-value SMMP">Social Media Management   99%</div>
                                        </div>

                                        <div class="progress">
                                            <div class="progress-value GHP">GITHUB   85%</div>
                                        </div>

                                        <div class="progress">
                                            <div class="progress-value BSP">Bootstrap   90%</div>
                                        </div>
                                </div>
                            </div>

                         </div>


                    </div>

                </div>
            </div>

        </MainContener>
    );
};


export default About ;