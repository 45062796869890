import MainContener from "../../Layout/MainContener";
import "./MyCv.css"
import "../../Components/ToggleSwitch.css" ;
import {  useState } from "react";



function MyCv(){

    const [ Language , SetLanguage ] =useState("EN") ;

    var deg = 0 ;
    function DirectionReversal(){
        var E1 = document.getElementById("CVAid");
        var E2=document.getElementsByClassName("IconsDownArrow")[0];
            deg = deg +180 ;
            E2.style.transform=`rotate(${deg}deg)`;

            if(E1.style.display==="" || E1.style.display==="none"){
                E1.style.display = "block" ;
            } 

            else if(E1.style.display==="block"){
                E1.style.display = "none" ;
            } ;

    };

    function ChangeLanguage(L){
        if (L==="EN"){
            document.getElementsByClassName("CVQSpan")[0].innerHTML="CV شنو يعني ";
            document.getElementsByClassName("CVAcP")[0].innerHTML="وتعني السيرة الذاتية" ;
            document.getElementsByClassName("CVDownloadP")[0].innerHTML="  🥹😂  CV اسف بس ما عندي " ;
            SetLanguage("AR");
            // document.getElementById("ArSpan").className="ToggleSwitchLeft2"
        }else if(L==="AR"){
            document.getElementsByClassName("CVQSpan")[0].innerHTML="What Is CV ?";
            document.getElementsByClassName("CVAcP")[0].innerHTML="CV is Curriculum vitae " ;
            document.getElementsByClassName("CVDownloadP")[0].innerHTML="Sorry , I Don't Have CV Now 🥹😂" ;
            SetLanguage("EN");


        }
    }

    return(
        <MainContener>
            <div className="CoverMyCv">
                <div className="PCVQA">
                    <div onClick={(e)=>DirectionReversal()} className="CVQ">
                        <div > <span className="CVQSpan"> What Is CV ? </span><img className='IconsDownArrow' src="Files/ICONS/down-triangular-arrow.png" alt="No" /> </div>
                    </div>

                    <div id="CVAid" className="CVAc">
                        <p className="CVAcP"> CV is Curriculum vitae  </p>
                    </div>
                </div>

                <div className="CVDownload">
                    <p className="CVDownloadP">Sorry , I Don't Have CV Now 🥹😂</p>
                </div>
                <div  className="ToggleSwitchDiv">
                            <div class="ToggleSwitchMid">

                                <label class="ToggleSwitchRocker ToggleSwitchRockerSmall">
                                    <input  onClick={e=>ChangeLanguage(Language)} type="checkbox"/>
                                        <span class="ToggleSwitchLeft">العربية</span>
                                        <span class="ToggleSwitchRight">EN</span>

                                        {/* <span onClick={e=>ChangeLanguage("AR")} id="ArSpan" class="ToggleSwitchLeft">العربية</span>
                                        <span onClick={e=>ChangeLanguage("EN")} id="EnSpan" class="ToggleSwitchRight">EN</span> */}
                                </label>

                            </div>
                        </div>
              
            </div>
        </MainContener>
    )
}

export default MyCv ;
