import MainContener from "../../Layout/MainContener";
import "./Services.css"


function Services(){
    return(
        <MainContener>
            <div className="CoverServices" >
                <div className="ServicessText">
                    Soon
                </div>
                <div className="ServicesDiv">
                    <p>We Will Add Services To Interest</p>
                </div>
            </div>

        </MainContener>
    );
};

export default Services ;