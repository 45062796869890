import "./AddPostDetails.css"
import { useSelector , useDispatch } from "react-redux";
import { postsActions } from "../../../Store/postsSlice";

function AddPostDetails(){
    
    const Data = useSelector((state)=>state);
    const dispatch = useDispatch();
    const PosterData = Data.Posts ;
    const {ChangePosterName, ChangePosterJob , ChangePostTitle ,ChangePostImage , ChangePostText }= postsActions ;

    function ChangePosterNameF(event) {
        let PosterName = event.target.value ;
        dispatch(ChangePosterName(PosterName));
    };

    function ChangePosterJobF(event) {
        let PosterJob = event.target.value ;
        dispatch(ChangePosterJob(PosterJob));
    };

    function ChangePostTitleF(event) {
        let PostTitle = event.target.value ;
        dispatch(ChangePostTitle(PostTitle));
    };
    
    
    function getBase64(file,callback = ()=>{}) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            callback(reader.result);
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
     }

    function ChangePostImageF(event) {
        let PostImage = event.target.files[0] ;
        getBase64(PostImage,(imageBase64)=>{
            console.log(imageBase64)
            dispatch(ChangePostImage(imageBase64));

            fetch("http://localhost:3010/api/upload-post-image",{
                method : "post" , 
                headers : {
                    'content-type':'application/json'
                },
                
                body: JSON.stringify({base64 : imageBase64 })
            } ).then(res=>res.json()).then(res=>{
                let ImageView = res.fileName ;
                ImageView = ImageView.split('/')[7];
                document.getElementsByClassName("ViewPostImage")[0].setAttribute('src',`/Files/Posts/PostsImage/TemperaturePostsImage/${ImageView}`) ;
            })
            .catch(error => console.log('error', error));
            
        });

    };

    function ChangePostTextF(event) {
        let PostText = event.target.value ;
        dispatch(ChangePostText(PostText));
    };

    function SendReq(event){
        let {PosterName ,PosterJob, PostTitle , PostImage ,PostText , PostEnable}=PosterData ;
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "PosterName": PosterName,
        "PosterJob": PosterJob,
        "PostTitle": PostTitle,
        "PostImage": PostImage,
        "PostText": PostText,
        "PostEnable": PostEnable 
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch("http://localhost:3010/api/AddNewPost", requestOptions)
        .then(response => response.json())
        .then(log => {
            if(log.status === "OK"){
                alert("OK , the Post waiting for approval ")
            }
            else{
                alert("You have error , Please try again ^_^ ")
            }
        }).then(
            setTimeout(()=>{
                window.location.reload(false)
            },100000)
        )
        .catch(error => console.log('error', error));

    }

    return(
            <div  className="DetailsPostWithAdd">

                <div >
                    <label className="LNameOfWriter" htmlFor="NameOfWriter" >Your Name :</label>
                    <input type="text" id="NameOfWriter" onChange={e =>  ChangePosterNameF(e) }/>
                </div>

                <div>
                    <label className="LJobOfWriter" htmlFor="JobOfWriter" >Your Job :</label>
                    <input type="text" id="JobOfWriter" onChange={e =>  ChangePosterJobF(e) } />
                </div>

                <div>
                    <label className="LPostTitle" htmlFor="PostTitle" >Post Title :</label>
                    <input type="text" id="PostTitle" onChange={e =>  ChangePostTitleF(e) } />
                </div>

                <div>
                    <label className="LPostImage" htmlFor="PostImage" >Post Image :</label>
                    <br/>
                    <input  type="file" id="PostImage" onChange={e =>  ChangePostImageF(e) } />
                </div>

                <div>
                    <label className="LTextPost" htmlFor="iTextPost" >Text Of The Post :</label>
                    <br/>
                    <textarea id="iTextPost" onChange={e =>  ChangePostTextF(e) } ></textarea>
                </div>

                <div>
                    <button className="PostButton" onClick={e => SendReq()}>POST</button>
                </div>

                
            </div>

    );
};

export default AddPostDetails ;