import { createSlice } from "@reduxjs/toolkit";




const IS = {
    PostId : 0,
    PosterName :"",
    PosterJob :"",
    PostTitle :"",
    PostImage:"",
    PostText:"",
    PostEnable: 0 
};



const postsSlice = createSlice({
    name:"Posts",
    initialState: IS , 
    reducers :{
 
        ChangePosterName :(state, action)=>{
            state.PosterName=action.payload ;
            return state ;
        },
        ChangePosterJob :(state, action)=>{
            state.PosterJob=action.payload ;
            return state ;
        },
        ChangePostTitle :(state, action)=>{
            state.PostTitle=action.payload ;
            return state ;
        },
        ChangePostImage :(state, action)=>{
            state.PostImage=action.payload ;
            return state ;
        },
        ChangePostText :(state, action)=>{
            state.PostText=action.payload ;
            return state ;
        }
        // ,
        // ChangeLeaderTime :(state,action)=>{
        //     state.LeaderTime = state.LeaderTime + action.payload ;
        // },
        // ResetLeaderTime :(state,action)=>{
        //     state.LeaderTime = action.payload;
        // }
    }
})


export const postsActions = postsSlice.actions ;


export default postsSlice.reducer ;