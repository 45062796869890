import MainContener from "../../Layout/MainContener";
import "./Projects.css" ;


function Projects(){
    return(
        <MainContener>
            <div className="CoverProjects">
                <div className="ProjectsText">
                    <p>Max Projects</p>
                </div>
                

                 <div className="ProjectsDiv">


                        <div className="ProjectDiv" >
                            <a href="https://www.zakimax.com" target="_blank" className="ProjectsLink" rel="noreferrer" >
                                <img src="Files/Logo/Logo Zaki Max Portfolio.png" alt="No" className="TTTTT"/>
                                <p>Zaki Max Portfolio</p>
                            </a>
                        </div>
    



                    {Array.from(new Array(5)).map((mt,i)=>{
                        return (
                        <div className="ProjectDiv Blan" key={i} style={{backgroundColor:`rgb(${Math.floor(Math.random()*255)},${Math.floor(Math.random()*255)},${Math.floor(Math.random()*255)})`}}>
                            <a href="/" onClick={e=>e.preventDefault()} className="ProjectsLink" rel="noreferrer" >
                                <img src="Files/Photo/Coming Soon 3.jpeg" alt="No" className="TTTTT"/>
                                <p>SooN</p>
                            </a>
                        </div>
                        )
                    })}
                </div> 

            
            </div>
        </MainContener>

    );
};

export default Projects ;