import MainContener from "../../Layout/MainContener" ;
import "./AccountLogin.css";
import {Link } from "react-router-dom";


function AccountLogin(){


    return(
        <MainContener>
            <div className="CoverAccountLogin">
                    <div className="LoginAccountDiv">
                    

                        <div>
                            <label className="LoginLabelClass" htmlFor="LoginEmailId" >Email : </label>
                            <input required className="LoginInputClass" type="email" id="LoginEmailId" placeholder="Your Email" />
                        </div>


                        <div >
                            <label className="LoginLabelClass" htmlFor="PasswordId" >Password : </label>
                            <input required className="LoginInputClass" type="password" id="PasswordId" placeholder="Your Password" />
                        </div>



                        <div>
                        <Link className="AccountOptionsButton"  to="/account/login" > Login </Link>
                        </div>

                   </div>
            </div>

        </MainContener>
    )
    


}

export default AccountLogin ;