import React from "react";
import MainContener from "../../Layout/MainContener";
import "./Posts.css"
import {Link } from "react-router-dom";

function Posts(){
    console.log("HEllo")
    let [AllData,SetAllData]=React.useState([]);
    function GetAllData(){
        var requestOptions = {
            method: 'POST'
            };
                
            fetch("http://localhost:3010/api/getPosts", requestOptions)
            .then(response => response.json())
            .then(log => {
                if(log.status === "OK"){
                    console.log(log.data)
                    SetAllData(log.data)
                }
                else{
                    console.log("no data")
                }
            })
            .catch(error => console.log('error', error));
    }
    
    React.useEffect(()=>{
        GetAllData();
    },[AllData.length])
    return(
        <MainContener>
            <div className="CoverPosts" >
                <div className="PostsText">
                    <p>Max Posts</p>
                </div>

                <div className="AddPostButtonDiv">
                    <Link className="AddPostButton" to="/posts/AddNewPost" > +      Add New Post </Link>
                </div>

                <div className="PostsDiv">


                    <div className="PostDiv" >
                        <a href="https://www.zakimax.com" target="_blank"  rel="noreferrer" >
                            <img src="Files/Logo/Logo Zaki Max Portfolio.png" alt="No" className="PostImage"/>
                            <p>Zaki Max Portfolio</p>
                        </a>
                    </div>



                    {Array.from(new Array(AllData.length)).map((mt,i)=>{
                                                    // let reeeed = document.getElementsByClassName("PostImage")[i] ;
                                                    // reeeed.src = `/Files/Posts/PostsImage/Postid_${AllData[i].id}.jpg` ;
                                                    // document.getElementsByClassName("PostImage")[i].setAttribute('src',`/Files/Posts/PostsImage/Postid_${AllData[i].id}.jpg`);
                        
                        if(AllData[i].PostEnable){
                            return (
                                <div  className="PostDiv" key={i} >
                                    <a href="/" onClick={e=>e.preventDefault()} rel="noreferrer" >
                                        <img src={`/Files/Posts/PostsImage/${AllData[i].PostImageUrl}`} alt="No" className="PostImage"/>
                                        <p>{AllData[i].PosterName} ({AllData[i].PosterJob})</p>
                                        <p>{AllData[i].PostTitle}</p>
                                    </a>
                                </div>
                            )
                        }
                        else {
                            return <React.Fragment key={i}></React.Fragment>
                        }


                        })};
                </div> 



            </div>

        </MainContener>
    );
};

export default Posts ;