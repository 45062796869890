import { useState } from "react";
import "./SignUpTerms.css"



function SignUpTerms(props={'SignUpTermsPopUpDiv':String() , 'onTermStateChange':()=>{}}){

    const [SignUpTermsPopUpDiv , SetSignUpTermsPopUpDiv] = useState(props.SignUpTermsPopUpDiv);
    const [Agr,SetAgr] = useState(props.Agr)

    // function onChangeeee (){
    //     props.onChange(SignUpTermsPopUpDiv)
    // } 

    return(
        <div className="SignUpTermsPopUp">

        <div>Hello</div>
        <div>{props.SignUpTermsPopUpDiv}</div>
        <div>{props.SignUpTermsPopUpDiv==='SignUpTermsPopUpDivHide'?'0':'1'}</div>




        <div>
            <button onClick={()=> props.onTermStateChange(1)}>Agree</button> 
            <button onClick={()=> props.onTermStateChange(0)} >Close</button>
        </div>



    </div>
    )

}

export default SignUpTerms ;