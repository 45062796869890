import {  useState } from "react";
import MainContener from "../../Layout/MainContener" ;
import "./AccountSignUp.css";
import {Link } from "react-router-dom";
import SignUpTerms from "../../Components/PopUpWindows/SignUpTerms/SignUpTerms";


function AccountSignUp(){

    // const [ Name , SetName ] =useState("") ;
    const [ FName , SetFName ] =useState("") ;
    const [ LName , SetLName ] =useState("") ;
    let Name = FName+ " " + LName ;

    const [ UserName , SetUserName ] =useState("") ;
    const [ Email , SetEmail ] =useState("") ;
    const [ Password , SetPassword ] =useState("") ;
    const [ BirthDay , SetBirthDay ] =useState("") ;
    const [ Gender , SetGender ] =useState("") ;
    const [ Country , SetCountry ] =useState("") ;

    const [SignUpTermsPopUpDiv , SetSignUpTermsPopUpDiv] = useState("SignUpTermsPopUpDivHide");

    const [agreeState , setAgreeState] = useState(null)


    // const [state=initialState, dispatch] = useReducer((s,d)=>({ ...s , ...d }),{UserName1 :"" , Email1 :""})



 let DAAATAAA = [ Name , UserName , Email , Password , BirthDay , Gender , Country ]

    return(
        <MainContener>
            <div className="CoverAccountSignUp">
                <div className={SignUpTermsPopUpDiv}>
                <SignUpTerms SignUpTermsPopUpDiv={SignUpTermsPopUpDiv} onTermStateChange={value=>{SetSignUpTermsPopUpDiv('SignUpTermsPopUpDivHide');setAgreeState(value)}} />

                </div>
                   <div className="SignUpAccountDiv">
                    
                        <div >
                            <label className="SignUpLabelClass" htmlFor="NameFId" >Your Name : </label>
                            <input required className="SignUpInputClass" type="text" id="NameFId" placeholder="First Name" onChange={e=> SetFName( e.target.value ) } />
                            <input required className="SignUpInputClass" type="text" id="NameLId" placeholder="Last Name"  onChange={e=> SetLName( e.target.value ) }/>

                            
                        </div>

                        <div >
                            <label className="SignUpLabelClass" htmlFor="UserNameId" > UserName : </label>
                            <input required className="SignUpInputClass" type="text" id="UserNameId" placeholder=" The UserName Is For You Only" onChange={e=> SetUserName( e.target.value ) }/>
                        </div>

                        
                        <div >
                            <label className="SignUpLabelClass" htmlFor="EmailId" >Email : </label>
                            <input required className="SignUpInputClass" type="email" id="EmailId" placeholder="Your Email" onChange={e=> SetEmail( e.target.value ) } />
                        </div>


                        <div >
                            <label className="SignUpLabelClass" htmlFor="PasswordId" >Password : </label>
                            <input required className="SignUpInputClass" type="password" id="PasswordId" placeholder="Password" onChange={e=> SetPassword( e.target.value ) }/>
                        </div>


                        <div >
                            <label className="SignUpLabelClass" htmlFor="BirthDayId" >BirthDay : </label>
                            <input required className="SignUpInputClass" type="date" id="BirthDayId" onChange={e=> SetBirthDay( e.target.value ) }/>
                        </div>


                        <div >
                            <label className="SignUpLabelClass" htmlFor="GenderId" >Gender : </label>
                            <select id="GenderId" className="SignUpInputClass" onChange={e=> SetGender( e.target.value ) }>
                                <option selected hidden>Select Your Gender</option>
                                <option value="Male">Male</option>
                                <option  value="Female">FeMale</option>
                            </select>
                        </div>

                        <div >
                            <label className="SignUpLabelClass" htmlFor="CountryId" >Your Country : </label>

                            <select id="CountryId" className="SignUpInputClass" onChange={e=> SetCountry( e.target.value ) }>
                                <option selected hidden>Select Your Country</option>
                                <option value="Iraq">Iraq</option>
                                <option  value="UnitedState">United State</option>
                                <option value="Turkey">Turkey</option>
                                <option value="UAE">UAE</option>
                                <option value="Saudi">Saudi</option>
                                <option value="Syria">Syria</option>
                                <option value="Lebanon">Lebanon</option>



                            </select>

                        </div>

                        <div>
                            <input id="TearmsAgreeId" type="checkbox" value="true"/>
                            <label className="SignUpLabelClass"  htmlFor="TearmsAgreeId"> Agree To The <p className="SignUpTermsP" onClick={()=> SetSignUpTermsPopUpDiv("SignUpTermsPopUpDivShow")} >Terms And Acknowledge</p> The Privacy Policy </label>
                            <button onClick={()=>{console.log(DAAATAAA)}} >OK</button>

                        </div>

                        <div>
                        <Link className="AccountOptionsButton" to="/account/signup"> SignUp</Link>
                        </div>

                    </div>
            </div>

        </MainContener>
    )
    


}

export default AccountSignUp ;