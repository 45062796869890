import { createSlice } from "@reduxjs/toolkit";




const IS = {
    Name :"",
    UserName :"",
    Email :"",
    Password:"",
    Birthday:"",
    Gender:"",
    Country:"",
    TearmsAgree : 0 ,
    EmailVerification : 0 ,
    AccountEnable: 0 
};



const CreateAccountSlice = createSlice({
    name:"CreateAccount",
    initialState: IS , 
    reducers :{

        ChangeName :(state, action)=>{
            state.Name=action.payload ;
            return state ;
        },
        ChangeUserName :(state, action)=>{
            state.UserName=action.payload ;
            return state ;
        },
        ChangeEmail :(state, action)=>{
            state.Email=action.payload ;
            return state ;
        },
        ChangePassword :(state, action)=>{
            state.Password=action.payload ;
            return state ;
        },
        ChangeBirthday :(state, action)=>{
            state.Birthday=action.payload ;
            return state ;
        },
        ChangeGender :(state, action)=>{
            state.Gender=action.payload ;
            return state ;
        },
        ChangeCountry :(state, action)=>{
            state.Country=action.payload ;
            return state ;
        }

    }
})


export const CreateAccountActions = CreateAccountSlice.actions ;


export default CreateAccountSlice.reducer ;