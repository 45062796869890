import MainContener from "../../Layout/MainContener" ;
import "./Contact.css";
import {Link } from "react-router-dom";

function Contact(){
    return (
        <MainContener>
            <div className="CoverContact">
                <div className="ContactText">
                    Contact
                </div>

                <div className="ContactDiv">
                        <div className="ContactDis1">You Can Follow Or Contact With Me On  </div>
                    <div className="ContactDivi">

                        <div className="ContactLinksDiv">
                            <a className="ContactLink" href="https://www.instagram.com/zakimax3" target="_blank" rel="noreferrer" ><img className="ContactIcon" alt="No" src="Files/ICONS/instagram.png"/> Instagram</a>
                            <a className="ContactLink" href="https://www.facebook.com/share/Ct6o5rmhcL2piUKP/?mibextid=qi2Omg" target="_blank" rel="noreferrer" ><img className="ContactIcon" alt="No" src="Files/ICONS/facebook.png"/> FaceBook</a>
                            <a className="ContactLink" href="https://t.me/zakimax3" target="_blank" rel="noreferrer" ><img className="ContactIcon" alt="No" src="Files/ICONS/telegram.png"/> Telegram</a>
                            <a className="ContactLink" href="https://github.com/zakimax3" target="_blank" rel="noreferrer" ><img className="ContactIcon" alt="No" src="Files/ICONS/github.png"/> GitHub</a>
                            <a className="ContactLink" href="https://www.linkedin.com/in/zakimax/" target="_blank" rel="noreferrer" ><img className="ContactIcon" alt="No" src="Files/ICONS/linkedin.png"/> Linkedin</a>
                            <a className="ContactLink" href="https://www.snapchat.com/add/zakimax3" target="_blank" rel="noreferrer" ><img className="ContactIcon" alt="No" src="Files/ICONS/snapchat.png"/> SnapChat</a>
                            <a className="ContactLink" href="https://tellonym.me/zakimax" target="_blank" rel="noreferrer" ><img className="ContactIcon" alt="No" src="Files/ICONS/tellonym.png"/> Tellonym</a>
                            <a className="ContactLink" href="mailto:zaki@zakimax.com" target="_blank" rel="noreferrer" ><img className="ContactIcon" alt="No" src="Files/ICONS/email.png"/> Email</a>
                            <a className="ContactLink" href="https://www.youtube.com/@zakimax9787" target="_blank" rel="noreferrer" ><img className="ContactIcon" alt="No" src="Files/ICONS/youtube.png"/> YouTube</a>
                            <a className="ContactLink" href="https://discord.com/invite/nnPhQXUd" target="_blank" rel="noreferrer" ><img className="ContactIcon" alt="No" src="Files/ICONS/discord.png"/> Discord</a>
                            <a className="ContactLink" href="https://www.tiktok.com/@zaki.max" target="_blank" rel="noreferrer" ><img className="ContactIcon" alt="No" src="Files/ICONS/tiktok.png"/> TikTok</a>
                            <a className="ContactLink" href="w" target="_blank" rel="noreferrer" ><img className="ContactIcon" alt="No" src="Files/ICONS/whatsapp.png"/> WhatsApp</a>
                        </div>


                    </div>

                    <div className="ContactDis2">
                        <p>* If You Have Any Question Or Comment , it is Better Write it in Question Area </p>

                        <Link className="QuestionsAreaButton"  to="/contact/QuestionsArea" > Questions Area</Link>

                    </div>



                </div>
            </div>
        </MainContener>
    )
}

export default Contact ;