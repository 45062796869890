import {configureStore } from "@reduxjs/toolkit";
// import counterSlice from "./CounterSlice";
// import LeaderSlice from "./LeaderSlice";
import postsSlice from "./postsSlice";
import CreateAccountSlice from "./CreateAccountSlice";

// const reducer = (
//     state={hi:6},
//     action={'type':String(),'data':Object()}
// )=>{
//     if( type==='update-hi' ){
//         state.hi = data.hi
//     }
//     return state
// }

const store = configureStore({reducer:{ Posts : postsSlice , CreateAccount : CreateAccountSlice}});
// const store = configureStore({
//     reducer
// })


export default store ;