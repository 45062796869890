import React from 'react'
import {createBrowserRouter,RouterProvider} from "react-router-dom";
import Home from "../Pages/Home/Home";
import About from '../Pages/About/About';
import Posts from '../Pages/Posts/Posts';
import Projects from '../Pages/Projects/Projects';
import Services from '../Pages/Services/Services';
import Events from '../Pages/Events/Events.js';
import MyCv from '../Pages/MyCv/MyCv';
import Contact from '../Pages/Contact/Contact';
import AddNewPost from '../Pages/Posts/AddNewPosts/AddNewPost';
import QuestionsArea from '../Pages/Contact/QuestionsArea/QuestionsArea.js'
// import Account from '../Pages/Account/Account.js';
import AccountLogin from '../Pages/Account/AccountLogin.js';
import AccountSignUp from '../Pages/Account/AccountSignUp.js';

const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/home",
      element: <Home />,
    },
    {
      path:"/about",
      element: <About />
    },
    {
      path:"/posts",
      element: <Posts />
    },
    {
      path:"/projects",
      element: <Projects />
    },
    {
      path:"/services",
      element: <Services />
    },
    {
      path:"/events",
      element: <Events />
    },
    {
      path:"/mycv",
      element: <MyCv />
    },
    {
      path:"/contact",
      element: <Contact />
    },    
    // {
    //   path:"/account",
    //   element: <Account />
    // },

    // Outhers
    {
      path:"/posts/AddNewPost",
      element: <AddNewPost />
    },
    {
      path:"/contact/QuestionsArea",
      element: <QuestionsArea />
    },
    {
      path:"/account/login",
      element: <AccountLogin />
    },
    {
      path:"/account/signup",
      element: <AccountSignUp />
    }

  ])
  

function Router() {
  return (
    <div>
        <RouterProvider router={router}/>
    </div>
  )
}

export default Router