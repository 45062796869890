import "./AddPostView.css"
import { useSelector , useDispatch } from "react-redux";


function AddPostView(){

    const Data = useSelector((state)=>state);
    const PostData = Data.Posts ;
    // let NName = testA.PosterName ;

    return(
            <div className="ViewPostWithAdd">
                <p className="PNameOfWriter"> By {PostData.PosterName} (  {PostData.PosterJob} )</p>
                <img alt="Viewer" src="" className="ViewPostImage"/>
                <p className="ViewTextPost">
                    Post Title : {PostData.PostTitle} 
                    <br/>
                    Post Text :
                    <br/>
                     {PostData.PostText} 
                    <br/>
                    
                
                 </p>
            </div>

    );
};

export default AddPostView ;