import MainContener from "../../../Layout/MainContener";
import "./AddNewPost.css";
import { useSelector , useDispatch } from "react-redux";
import { postsActions } from "../../../Store/postsSlice";
import { useEffect } from "react";
import AddPostView from "./AddPostView";
import AddPostDetails from "./AddPostDetails";



function AddNewPost(){
    return(
    <MainContener>
        <div className="CoverAddPost" > 

            <div className="AddPostView">
                <AddPostView/>
            </div>

            <div className="AddPostDetails">
                <AddPostDetails/>
            </div>

        </div>
    </MainContener>
);

};

export default AddNewPost ;



// const test = useSelector((state)=>state);
// const dispatch = useDispatch();
// const {ChangeNName}= postsActions ;
// const testA = test.Posts ;
// let NName = testA.NName ;



// function getBase64(event) {
//     let file = event.target.files[0];
//     var reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = function () {
//         console.log('start uploading')
//         fetch(`http://localhost:3005/upload-file`,{ method:'POST','headers':{ 'content-type':'application/json' },'body':JSON.stringify({ base64:reader.result }) })
//         .then(res=>res.json())
//         .then(console.log)
//         .catch(console.log)
//     //   console.log(reader.result);
//     };
//     reader.onerror = function (error) {
//       console.log('Error: ', error);
//     };
//  }



//  function GetUserDi(){
//     fetch(`http://localhost:3005/user-details`,{ method:'POST','headers':{ 'content-type':'application/json' },'body':JSON.stringify({ Userid : "Ahmed123" }) })
//     .then(res=>res.json())
//     .then(console.log)
//     .catch(console.log)
//  }
 

//  function RETEEEe(ee) {
//     NName = ee.target.value ;
//     dispatch(
//         ChangeNName(NName)
//     );

//  }
//  useEffect(() => {
//     console.log("High")
//     console.log(testA)     })



// return(
//     <MainContener>
//         <div className="CoverAddPost" >
//             <div className="ViewPostWithAdd">
//                 <p className="PNameOfWriter"> By @@user {NName} ( @@Jop )</p>
//                 <img alt="Viewer" src="" className="ViewPostImage"></img>
//                 <p className=""></p>
//             </div>
            
                                // <div className="DetailsPostWithAdd">

                                //     <div>
                                //         <label className="LNameOfWriter" htmlFor="NameOfWriter" >Your Name :</label>
                                //         <input type="text" id="NameOfWriter" onChange={e =>  RETEEEe(e) } />
                                //     </div>

                                //     <div>
                                //         <label className="LJobOfWriter" htmlFor="JobOfWriter" >Your Job :</label>
                                //         <input type="text" id="JobOfWriter" />
                                //     </div>

                                //     <div>
                                //         <label className="LPostTitle" htmlFor="PostTitle" >Post Title :</label>
                                //         <input type="text" id="PostTitle" />
                                //     </div>

                                //     <div>
                                //         <label className="LPostImage" htmlFor="PostImage" >Post Title :</label>
                                //         <input onChange={e =>getBase64(e)} type="file" id="PostImage" />
                                //     </div>

                                //     <div>
                                //         <label className="LTextPost" htmlFor="iTextPost" >Text Of The Post :</label>
                                //         <br/>
                                //         <textarea id="iTextPost" ></textarea>
                                //     </div>


                                //     <button onClick={e=> GetUserDi()}>ONNN</button>

                                    
                                // </div>
//         </div>

//     </MainContener>
// );