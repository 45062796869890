import { useState  } from "react";
import MainContener from "../../../Layout/MainContener" ;
import "./QuestionsArea.css";


function QuestionsArea(){

    const [AddQuestionForm , SetAddQuestionForm ] = useState("none");

    function ShowHideAddQuestionForm(){
        SetAddQuestionForm(AddQuestionForm ==="none" ? "block" : "none")
    }

    return(
        <MainContener>
            <div className="CoverQuestionsArea">
                <div className="QuestionsAreaText">
                    <p>Questions Area</p>
                </div>

                <div>
                    <button className="AddQuestionsButton" onClick={e=>ShowHideAddQuestionForm()}>Add Question</button>
                    <div className="AddQuestionForm" style={{display:AddQuestionForm}}>
                        <div className="AddQuestionFormDiv">
                            <div>
                                <label for="AddQuestionNameText">
                                    Your Name :  
                                </label>
                                <input id="AddQuestionNameText" type="text"/>
                            </div>
                            <div>
                                <label for="AddQuestionQuestionText">
                                    Your Question :  
                                </label>
                                <textarea  id="AddQuestionQuestionText" ></textarea>
                            </div>
                            <div>
                                <button onClick={e=>ShowHideAddQuestionForm()}>Close</button>
                                <button onClick={e=>ShowHideAddQuestionForm()}>Add Question</button>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="QuestionsDiv">

                    <div className="QuestionAreaDiv">
                        <div className="QuestionDiv">
                            <p style={{fontSize:"2.2vw" }}>Question 1 from Ahmed in 2021: </p> 
                            <p><span>What is your Name </span> ?</p>
                        </div>

                        <div className="AnswerDiv">
                            <p><span>Answer : </span> <span>My Name Is zaki</span> .</p>
                        </div>
                    </div>

                    



                </div>

                

            </div>
        </MainContener>
    )
}

export default QuestionsArea ;
